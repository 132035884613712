/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import "packs/datatables";
import { initSelect2 } from 'packs/init_select2';

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  // initSelect2();
});



$(document).ready(function() {
    $('#active_list').DataTable({
       "initComplete": function (settings, json) {
         $("#active_list").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
       },
       });
    });

 $(document).ready(function () {
  $('#company_active').DataTable({
     "initComplete": function (settings, json) {
       $("#company_active").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
     },
   });
 });



document.addEventListener("turbolinks:load", function () {
  initSelect2();
});
